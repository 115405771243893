import React, { Component } from 'react';
import sectionData from '../JSON/sectionData' ;
import Section from './Section';

class AboutMe extends Component {
	constructor(){
		super()
		this.state ={
			sections : sectionData
		}
	}

  render() {
  	const sectionItems = this.state.sections.map(item => <Section key={item.id} item={item} />)

    return(
    <div className="aboutmeDiv" >
    <p style={{fontFamily:'Consolas',fontWeight:'bolder',fontSize:'24px', textAlign:'center', color:'white'}}>A couple of things about me that you may find interesting</p>
   

<article>

  {sectionItems}
  
</article>
</div>
    )
  }
}

export default AboutMe;