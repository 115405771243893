const cardSQLData =[
{
	id :1,
	titleUrl: "url(https://cloudblogs.microsoft.com/uploads/prod/sites/32/2020/05/SQL.png) center/cover",
	cardText: "On my current job, I don't write much of SQL, most of the projects that I'm working on are using LINQ. Procedures, functions, and views are strictly DB team's job (that's the policy of the department). But still, I use SQL Server Management Studio daily for debugging purposes and I write SQL in some services that use ADO.NET. ",
	link:""
},
{
	id :2,
	titleUrl: "url(https://cloudblogs.microsoft.com/uploads/prod/sites/32/2020/05/SQL.png) center/cover",
	cardText: "During my previous job in Systec, my main duty was to create pages in Delphi with grids on it so most of the time I spend querying OracleDB. OracleDB hasn't got a management studio then, so I used Toad.",
	link:""
}/*,
{
	id :3,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqiJ6S5pCxBhlACyK2toHxfRRF_Qz3tZfn5P-NrT8qYaq4F7NQ&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqiJ6S5pCxBhlACyK2toHxfRRF_Qz3tZfn5P-NrT8qYaq4F7NQ&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default cardSQLData