const sectionData =[
{
	id : 1,
	color: "#FFA500", 
	text: "Father of one little boy, married, happily."

},
{
	id : 2,
	color: "#FFA500", 
	text: "I find great joy in basketball statistics pages, food (making & eating), comic books and books vulgaris."

},
{
	id : 3,
	color: "#FF6347", 
	text: "Favourite player? Air Michael Jordan. Obviously."

},
{
	id : 4,
	color: "#FF6347", 
	text: "One sentence that describes me the most has to be: Born to Python made to C#."

},
{
	id : 5,
	color: '#FF6347', 
	text: 'Not a leader, great follower tho.'

},
{
	id : 6,
	color: '#E90E50', 
	text: 'Favorite albums (not in particular order): Only Built For Cuban Lynx, Ill Communication, Tical, Apocalypse 91, Return To The 36 Chambers, Ironman.'

},
{
	id : 7,
	color: '#E90E50', 
	text: "Don't know a thing about current politics. But, I  can provide expertise in pre-WW2 USSR."

},
{
	id : 8,
	color: '#B81D6F', 
	text: 'Not the biggest fan of shaving, public transportation, driving, pollution, trap music and the new era of basketball. pizza fan.'

},
{
	id : 9,
	color: '#B81D6F', 
	text: "I' m the happiest when I'm with my family, or when I crush some problem on codility with 100%. Also, I listen to podcasts. A lot."

},
{
	id : 10,
	color: '#932CA7', 
	text: "You can say that I'm a full-stack developer, I guess...I prefer the term 'Will Code For Food'."

},
{
	id : 11,
	color: '#932CA7', 
	text: "I totally have a definite top five movie list: La Haine, Snatch, White Men Can't Jump, Death Rides A Horse."

},
{
	id : 12,
	color: '#782CB0', 
	text: 'I enjoy the most reading Philip Roth, dr Vladimir Protic and Robert Vieira.'

},
{
	id : 13,
	color: '#782CB0', 
	text: "Can bench-press ~150kgs. I'm old now."

}

]

export default sectionData