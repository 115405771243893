const delphiData =[
{
	id :1,
	titleUrl: "url(https://ih1.redbubble.net/image.656713774.0638/flat,750x,075,f-pad,750x1000,f8f8f8.jpg) center/cover",
	cardText: "During my first job as a programmer, I've written code in Delphi, the software was used for monitoring processes in the metals industry. The main reason they used Delphi is after you declare connection to Oracle DB in ini file and compile the project, resulting icon could be pasted anywhere and it will work.", 
	link:""
}/*,
{
	id :2,
	titleUrl: "url(https://image.flaticon.com/icons/png/512/919/919843.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :3,
	titleUrl: "url(https://image.flaticon.com/icons/png/512/919/919843.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://image.flaticon.com/icons/png/512/919/919843.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default delphiData