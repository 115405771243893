const efData =[
{
	id :1,
	titleUrl: "url(https://media.glassdoor.com/sqll/865737/codefirst-squarelogo-1409672439639.png) center/cover",
	cardText: "In most of our projects, we use EntityFramework Code-First approach, i.e. using EF to communicate with a database and creating classes for the domain entity rather than design database first, so I'm pretty serviceable in it.",
	link:""
}/*,
{
	id :2,
	titleUrl: "url(https://media.glassdoor.com/sqll/865737/codefirst-squarelogo-1409672439639.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :3,
	titleUrl: "url(https://media.glassdoor.com/sqll/865737/codefirst-squarelogo-1409672439639.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://media.glassdoor.com/sqll/865737/codefirst-squarelogo-1409672439639.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}
*/
]
export default efData