import React from 'react'

function Section (props){
	
		return(
			<section style={{backgroundColor: props.item.color}} className="section" >
				<p className="p1">{props.item.text}</p>
			</section>
			)
	
}
export default Section