const jsData =[
{
	id :1,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoTbAzhCYMgP2gjIoq56kAqj-EZUcqgXxyglycWuzn4dK2zMsT&s) center/cover",
	cardText: "Unfortunately, we have lots of old applications,  documentation lost or non-existent, so for quick fixes(client-side validation, retrieving a value from a control, etc.) I use JS daily. Also, in MVC projects, using AJAX is the easiest way to build cascading dropdowns, so that's that.",
	link:""
}/*,
{
	id :2,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoTbAzhCYMgP2gjIoq56kAqj-EZUcqgXxyglycWuzn4dK2zMsT&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :3,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoTbAzhCYMgP2gjIoq56kAqj-EZUcqgXxyglycWuzn4dK2zMsT&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoTbAzhCYMgP2gjIoq56kAqj-EZUcqgXxyglycWuzn4dK2zMsT&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default jsData