const reactData =[
{
	id :1,
	titleUrl: "url(https://icons-for-free.com/iconfiles/png/512/design+development+facebook+framework+mobile+react+icon-1320165723839064798.png) center/cover",
	cardText: "I started to learn REACT three months ago, and this page is my first project built in React.js, I'm fascinated by concepts of this library and I pursue opportunities to be better at it. Recently, I've made a react client page that consumes graphql server made in .net core.",
	link: "https://github.com/tokyo2mogadish/about_me_project"
}/*,
{
	id :2,
	titleUrl: "url(https://icons-for-free.com/iconfiles/png/512/design+development+facebook+framework+mobile+react+icon-1320165723839064798.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :3,
	titleUrl: "url(https://icons-for-free.com/iconfiles/png/512/design+development+facebook+framework+mobile+react+icon-1320165723839064798.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://icons-for-free.com/iconfiles/png/512/design+development+facebook+framework+mobile+react+icon-1320165723839064798.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default reactData