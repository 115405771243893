import React, {Component} from 'react';
import {Grid, Cell} from 'react-mdl';
import Logo from './avataaars.png';
import Education from './Education';
import Experience from './Experience';
import Skills from './Skills';
import educationData from '../JSON/educationData';
import experienceData from '../JSON/experienceData';
import skillsData from '../JSON/skillsData' ;

class Resume extends Component{
	constructor(){
		super()
		this.state ={
			educations: educationData,
			experiences: experienceData,
			skills: skillsData
		}
	}
	render(){
		const educationItems = this.state.educations.map(item => <Education key={item.id} item={item} />)
		const experienceItems = this.state.experiences.map(item => <Experience key ={item.id} item ={item} />)
		const skillItems = this.state.skills.map(item => <Skills key={item.id} item ={item} />)

		return(
			<div className ="resumeDiv" >
				<Grid>
					<Cell>
						<div style={{textAlign: 'center'}}>
							<img
								src={Logo}
								alt="avatar"
								style ={{height: '200px'}}
							/>
						</div>
						<h2 style = {{paddingTop: '2em'}}>Srđan Milojević</h2>
						<h4 style = {{color:'grey'}} >Programmer</h4>
						<hr style = {{borderTop: '3px solid #833fb2', width: '50%'}}></hr>
						<p>Reliable. Good at some things. Willing to learn. Hard-working. Always on time. Mostly fun guy to be around.</p>
						<hr style = {{borderTop: '3px solid #833fb2', width: '50%'}}></hr>
						<h5>City</h5>
						<p>Belgrade</p>
						<h5>Phone</h5>
						<p>+381 (69) 1256 440</p>
						<h5>Email</h5>
						<p>smilojevic@smilojevic.com</p>
						<hr style = {{borderTop: '3px solid #833fb2', width: '50%'}}></hr>
					</Cell>
					<Cell className="resume-right-col" col={8}>
						<h2>Education</h2>
						{educationItems}						
						<hr style={{borderTop: '3px solid #e22947'}}/>
						<h2>Experience</h2>
						{experienceItems}
						<hr style={{borderTop: '3px solid #e22947'}}/>
						<h2>Skills</h2>
						{skillItems}
					</Cell>
				</Grid>
			</div>
			);
		
	}
}

export default Resume;
