import React, {Component} from 'react';
import { Tab, Tabs, Grid, Cell} from 'react-mdl';
import ProjectCard from './ProjectCard';
import cardCSharpData from '../JSON/cardCSharpData';
import cardSQLData from '../JSON/cardSQLData';
import jsData from '../JSON/jsData';
import reactData from '../JSON/reactData';
import delphiData from '../JSON/delphiData';
import htmlData from '../JSON/htmlData';
import efData from '../JSON/efData';
import mongoData from '../JSON/mongoData';
import pythonData from '../JSON/pythonData';

class Projects extends Component{
	constructor(props){
		super(props);
		this.state = {
			activeTab:0, 
			csharps: cardCSharpData, 
			sqls : cardSQLData, 
			jss : jsData, 
			reacts : reactData, 
			delphis : delphiData,
			htmls : htmlData, 
			efs : efData, 
			mongos : mongoData, 
			pythons : pythonData
		};
	}
	toggleCategories(){
		if(this.state.activeTab ===0){
			const csharpItems = this.state.csharps.map(item=><ProjectCard key={item.id} item= {item} /> )
		return(	
		<div className="projects-grid">				
			{csharpItems}		
		</div>
			)
	}
		else if(this.state.activeTab ===1){
			const sqlItems = this.state.sqls.map(item => <ProjectCard key = {item.id} item ={item} />)
			return(
		<div className="projects-grid">
			{sqlItems}
		</div>
				)
	}
		else if(this.state.activeTab ===2){
			const jsItems = this.state.jss.map(item => <ProjectCard key = {item.id} item = {item} />)
			return(
		<div className="projects-grid">
			{jsItems}
		</div>
				)
	}
		else if(this.state.activeTab ===3){
			const reactItems = this.state.reacts.map(item => <ProjectCard key ={item.id} item = {item} />)
			return(
		<div className="projects-grid">
			{reactItems}
		</div>
				)
	}
		else if(this.state.activeTab ===4){
			const delphiItems = this.state.delphis.map(item => <ProjectCard key ={item.id} item = {item} />)
			return(
		<div className="projects-grid">
			{delphiItems}	
		</div>
				)
	}
		else if(this.state.activeTab ===5){
			const htmlItems = this.state.htmls.map(item => <ProjectCard key = {item.id} item = {item} />)
			return(
		<div className="projects-grid">
			{htmlItems}	
		</div>
				)
	}
		else if(this.state.activeTab ===6){
			const efItems = this.state.efs.map(item => <ProjectCard key ={item.id} item ={item} />)
			return(
		<div className="projects-grid">
			{efItems}	
		</div>
				)
	}
		else if(this.state.activeTab ===7){
			const mongoItems = this.state.mongos.map(item => <ProjectCard key ={item.id} item = {item} />)
			return(
		<div className="projects-grid">
			{mongoItems}
		</div>
				)
	}
		else if(this.state.activeTab ===8){
			const pythonItems = this.state.pythons.map(item => <ProjectCard key ={item.id} item = {item} />)
			return(
		<div className="projects-grid">
			{pythonItems}	
		</div>
				)
		

	}

	}

	render(){
		return(
		<div className = "projectDiv">
			<div className="category-tabs">
				<Tabs activeTab={this.state.activeTab} onChange={(tabId)=>this.setState({activeTab:tabId})} ripple>
					<Tab>C#</Tab>
					<Tab>SQL</Tab>
					<Tab>JavaScript</Tab>
					<Tab>React</Tab>
					<Tab>Delphi</Tab>
					<Tab>HTML/CSS</Tab>
					<Tab>EF</Tab>
					<Tab>MongoDb</Tab>
					<Tab>Python</Tab>
					
				</Tabs>
				<section className="projects-grid">
					<Grid>
						<Cell col={12}>
							<div className="content">{this.toggleCategories()}</div>
						</Cell>
					</Grid>
					
				</section>
				
			</div>
		</div>
			);
		
	}
}

export default Projects;
