import React from 'react';
import {Switch, Route} from 'react-router-dom';
import LandingPage from './LandingPage.js';
import AboutMe from './AboutMe.js';
import Contact from './Contact.js';
import Resume from './Resume.js';
import Projects from './Projects.js';

const Main = () =>(
		<Switch>
			<Route path="/" exact component = {LandingPage} />
			<Route path ="/AboutMe" component = {AboutMe} />
			<Route path = "/Contact" component = {Contact} />
			<Route path = "/Resume" component = {Resume} />
			<Route path = "/Projects" component = {Projects} />
			<Route path = "*" component = {LandingPage} />
		</Switch>	
)

export default Main