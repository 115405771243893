const cardCSharpData =[
{
	id :1,
	titleUrl: "url(https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/7e/d2/86/7ed286dd-96ca-b0e2-d1b0-21c09146154b/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x600wa.png)center/cover",
	cardText: "BIFIDEX, online platform that offers extensive business intelligence by combining financial and statutory information from directly connected registry sources (Serbia, Republika Srpska, North Macedonia, Albania).Technologies used by our side are WCF and ADO.NET.",
	link: "https://www.bifidex.com/en/home"
},
{
	id :2,
	titleUrl: "url(https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/7e/d2/86/7ed286dd-96ca-b0e2-d1b0-21c09146154b/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x600wa.png)center/cover",
	cardText: "Software solution for online filing, verification,processing and publishing of financial statements. An application with over 300.000 users and over 40.000 users working on it per day at its peak. Solution consists of web application, system core, web services and many automation of numerous business processes.",
	link: "https://reid.apr.gov.rs/SSO/"
},
{
	id :3,
	titleUrl: "url(https://is5-ssl.mzstatic.com/image/thumb/Purple113/v4/7e/d2/86/7ed286dd-96ca-b0e2-d1b0-21c09146154b/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x600wa.png)center/cover",
	cardText: "Software solution that receives well over 500.000 hits per day with plenty of data scraping attempts.",
	link:"https://pretraga2.apr.gov.rs/unifiedentitysearch"
}//,
//{
//	id :4,
//	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMEoJXdYre5_ct17Fx5ni3XKZErvEcKhqEWnxe7ycdyC8fW0lU&s)center/cover",
//	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
//}

]
export default cardCSharpData