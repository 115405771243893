import React, {Component} from 'react';
import {Grid, Cell, List, ListItem, ListItemContent} from 'react-mdl';
import Logo from './avataaars.png';

class Contact extends Component{
	render(){
		return(
			<div className = "contact-body">
				<Grid className = "contact-grid">
					<Cell col = {6}>
						<h2>Srđan Milojević</h2>
						<img
							src={Logo}
							alt = "avatar"
							style ={{height: '250px'}}
						/>
						<p style ={{ width:'75%', margin: 'auto', paddingTop: '1em'}}>
							I live in Belgrade, Serbia, Southeast Europe. 
						</p>
					</Cell>
                    <Cell col = {6}>
                    	<h2>Contact Me</h2>
                    	<hr/>
                    	<div className ="contact-list">
                    		<List>

							  <ListItem>
							    <ListItemContent style ={{fontSize :'30px', fontFamily :'Anton', textAlign:'left'}}>
							    	<i className = "fa fa-phone-square" aria-hidden ="true"/>
							    	+381(69)-1256-440
							    </ListItemContent>
							  </ListItem>

							  <ListItem>
							    <ListItemContent style ={{fontSize :'30px', fontFamily :'Anton', textAlign:'left'}}>
							    	<i className = "fa fa-envelope" aria-hidden ="true"/>
							    	smilojevic@smilojevic.com
							    </ListItemContent>
							  </ListItem>

							  <ListItem>
							    <ListItemContent style ={{fontSize :'30px', fontFamily :'Anton', textAlign:'left'}}>
							    	<i className = "fa fa-skype" aria-hidden ="true"/>
							    	srdjan13milojevic	
							    </ListItemContent>
							  </ListItem>
						   </List>
                    	</div>
                    	
                    </Cell>
				</Grid>
			</div>
			);
		
	}
}

export default Contact;
