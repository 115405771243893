const mongoData =[
{
	id :1,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbfi2qs3pOF09v6rLDdugQr-g_O5zhkxEqTL-KWI94xCvPdmJ6&s) center/cover",
	cardText: "I use MongoDB with RoboMongo daily in the debugging process, because, as the main registry of all economic entities, we have to store lots of documents within every entity, and MongoDB was the happiest solution. So, when there is a problem with some client, I find his financial statement stored in Mongo, change the creator and user in JSON to mine credentials, so I could debug his case.", 
	link:""
}/*,
{
	id :2,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbfi2qs3pOF09v6rLDdugQr-g_O5zhkxEqTL-KWI94xCvPdmJ6&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :3,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbfi2qs3pOF09v6rLDdugQr-g_O5zhkxEqTL-KWI94xCvPdmJ6&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbfi2qs3pOF09v6rLDdugQr-g_O5zhkxEqTL-KWI94xCvPdmJ6&s) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default mongoData