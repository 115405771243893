const skillsData = [
{
	id: 1,
	skill : "C#",
	progress: 80
},
{
	id: 2,
	skill : "Python",
	progress: 60
},
{
	id: 3,
	skill : "JavaScript",
	progress: 60
},
{
	id: 4,
	skill : "React",
	progress: 60
},

{
	id: 5,
	skill : "Turbo Pascal",
	progress: 80
},
{
	id: 6,
	skill : "HTML/CSS",
	progress: 65
},
{
	id: 7,
	skill : "PHP",
	progress: 40
},
{
	id: 8,
	skill : "T-SQL/SQL/MySQL",
	progress: 65
}

]

export default skillsData
