const educationData = [
{
	id : 1, 
	startYear : "1993", 
	endYear : "1997",
	schoolName : "High School Moša Pijade",
	shoolDescription : "Philologically orientated high-school, so lots of Latin, French, German and reading. But it had pretty good programming professor, had fun with Basic."
},
{
	id : 2, 
	startYear : "1997", 
	endYear : "2019",
	schoolName : "Faculty of Forestry",
	shoolDescription : "During my studies at the Faculty of Forestry, I had to learn TurboPascal which at the time looked bit old-timey but it eventually helped me land my first programming job. Also, I learned the basics of SQL there."
},
{
	id : 3, 
	startYear : "2008", 
	endYear : "2009",
	schoolName : "IT Academy",
	shoolDescription : "C#, ADO.Net, ASP.Net, Php, SQL. My final project was a web app that processes basketball statistics."
},
{
	id : 4, 
	startYear : "2013", 
	endYear : "2014",
	schoolName : "Additional Education",
	shoolDescription : "Cryptography I, Principles of Computing 1 and 2, Interactive Programming in Python 1 and 2"
}
]

export default educationData