import React from 'react';
import{Layout, Header, Drawer, Navigation, Content} from 'react-mdl';
import './App.css';
import Main from './Components/Main.js';
import {Link} from 'react-router-dom';

function App() {
  return (
    /*<div style={{height: '300px', position: 'relative'}}>*/
    <div className="demo-big-content">
    <Layout>
        <Header className="header-color" title={<Link style ={{textDecoration: 'none', color:'white'}} to="/">My Portfolio</Link>} scroll>
            <Navigation>
                <Link to="/Resume">Resume</Link>
                <Link to="/AboutMe">About Me</Link>
                <Link to="/Projects/">Projects</Link>
                <Link to="/Contact">Contact</Link>
            </Navigation>
        </Header>
        <Drawer title={<Link style ={{textDecoration: 'none', color:'black'}} to="/">My Portfolio</Link>} >
            <Navigation>
                <Link to="/Resume">Resume</Link>
                <Link to="/AboutMe">About Me</Link>
                <Link to="/Projects/">Projects</Link>
                <Link to="/Contact">Contact</Link>
            </Navigation>
        </Drawer>
        <Content>
            <div className="page-content" />
            <Main/>
        </Content>
    </Layout>
    </div>  
  );    
}

export default App;
