import React from 'react';
import { Card, CardTitle,CardText, CardActions, Button, CardMenu, IconButton } from 'react-mdl';

function ProjectCard(props){

	return (
		<Card shadow={5} style={{minWidth: '450', margin: '5px', padding: '15px'}}>
				<CardTitle style={{color:'black', height:'176px', background:props.item.titleUrl}}>
					
				</CardTitle>
				<CardText>
					{props.item.cardText}
				</CardText>
				<CardActions border>
					
						<Button colored onClick={(e): void => {
      						e.preventDefault();
      						window.location.href=props.item.link;
      						}}>
      						{props.item.link!==""?"LINK":""}
      						</Button>
					
				</CardActions>
				<CardMenu style={{color:'#fff '}}>
					<IconButton name="share" />
				</CardMenu>
			</Card>
		)
}

export default ProjectCard