const htmlData =[
{
	id :1,
	titleUrl: "url(https://www.pngitem.com/pimgs/m/206-2069813_file-css-and-html-css-logo-svg-hd.png) center/cover",
	cardText: "Although our design is mostly outsourced, I have been doing and still do my fair share in HTML/CSS because our bussines is really big on changing layout constantly.",
	link: ""
}/*,
{
	id :2,
	titleUrl: "url(https://forgeahead.io/wp-content/uploads/2015/01/logo-HTML-CSS.jpg) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :3,
	titleUrl: "url(https://forgeahead.io/wp-content/uploads/2015/01/logo-HTML-CSS.jpg) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
},
{
	id :4,
	titleUrl: "url(https://forgeahead.io/wp-content/uploads/2015/01/logo-HTML-CSS.jpg) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default htmlData