import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Logo from './circle-cropped.png';

class LandingPage extends Component {
  render() {
    return(
      <div style={{width: '100%', margin: 'auto' }}>
        <Grid className="landing-grid">
          <Cell col={12}>
            <img
              src ={Logo}
              alt="avatar"
              className="avatar-img"
              />

            <div className="banner-text">
              <h1> Web Developer/ Programmer </h1>

            <hr/>

          <p>C# | SQL | Delphi | Python | MongoDB | JavaScript | React | ASP.NET | ADO.NET | Entity Framework </p>


        <div className="social-links">

          {/* LinkedIn */}
          <a href="https://www.linkedin.com/in/sr%C4%91an-milojevi%C4%87-bb692531/" rel="noopener noreferrer" target="_blank">
            <i className="fa fa-linkedin-square" aria-hidden="true" />
          </a>

        </div>
            </div>
          </Cell>
        </Grid>
      </div>
    )
  }
}

export default LandingPage;