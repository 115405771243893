const pythonData =[
{
	id :1,
	titleUrl: "url(https://png.pngitem.com/pimgs/s/159-1595908_python-java-computer-programming-programming-language-python-3.png) center/cover",
	cardText: "The Financial Reports Application use IronPython for client-side scripting. When a user posts his financial form IronPython (different scripts are used for different financial forms) runs through its AOPs (fields in the form) and compare it with each other following complex accounting regulation.", 
	link:""
},
{
	id :2,
	titleUrl: "url(https://png.pngitem.com/pimgs/s/159-1595908_python-java-computer-programming-programming-language-python-3.png) center/cover",
	cardText: "Because of the new accounting law and completely new control rules(of which there are over 50,000) the vendor requested assistance in creating Python scripts for server side validation of input forms as they retained the previous solution with IronPython library. Due to the volume of work that would require too many man hours and that would lead to breaking deadlines, I created a python script that converts excel files with control rules supplied by the registry into python code. Also, I participated in making new forms", 
	link:""
}/*,
{
	id :3,
	titleUrl: "url(https://png.pngitem.com/pimgs/s/159-1595908_python-java-computer-programming-programming-language-python-3.png) center/cover",
	cardText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
}*/

]
export default pythonData