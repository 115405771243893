const experienceData = [
{
	id : 1,
	startYears : "2010",
	endYears : "2011",
	jobName : "L2 Programmer at Systec/Danieli Automation",
	jobDescription : "Created software used in monitoring processes for the metals industry (ranging from refining iron ore to processing long and flat products). Coded in Delphi with Oracle DB"
},
{
	id : 2,
	startYears : "2012",
	endYears : "2021",
	jobName : "Senior Software Developer at Serbian Business Registers Agency",
	jobDescription : "Software development (coding, integration with other systems), managing software maintenance and daily operation, writing software documentation, managing software change requests, testing, communicating with external vendors, participating in business analysis and communication with stakeholders, training new members of the team."
},
{	
	id : 3,
	startYears : "2021",
	endYears : "Present",
	jobName : "Senior Solution Developer at Engineering Software Lab",
	jobDescription : "Adding new modules to the current enterprise solution, fixing bugs related to various .NET versions on different clients, creating packages and procedures in Oracle DB"
}
]

export default experienceData