import React from 'react';
import { Grid, Cell } from 'react-mdl';

function Experience(props) {

    return(
      <Grid>
        <Cell col={4}>
          <p>{props.item.startYears} - {props.item.endYears} </p>
        </Cell>
        <Cell col={8}>
          <h4 style={{marginTop:'0px'}}>{props.item.jobName}</h4>
          <p>{props.item.jobDescription}</p>
        </Cell>
      </Grid>
    )
}

export default Experience;